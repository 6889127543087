import React, { useState } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./evaluationFinalEvalForm.data.form";
import InputSelect from "../../../utils/InputSelect";
import Select from "react-select";
import {
  Subtitle,
  AutoLabel,
  AutoValue,
  AutoIcon,
  ContainerTooltip,
  ContainerTooltipSup,
  InfoIcon,
} from "./evaluationEvalForm.styled";
import { Tooltip } from "react-tooltip";
import { POINTS, POINTS_ALL } from "../../../../constants/index";

const EvalFormView = (props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    cancelAction,
    onSelectSkillValue,
    professionalSkill,
    evaluation,
    onJustify,
  } = props;

  const comments = {};

  if (evaluation && evaluation.comments && evaluation.comments.length > 0) {
    for (const comment of evaluation.comments) {
      comments[`${comment.type}${comment.obj}`] = comment.comment;
    }
  }

  const points = [];
  for (const point of POINTS) {
    if (point.code !== -1) {
      points.push({
        value: point.code,
        label: t(point.label),
      });
    }
  }

  const getPoints = (point) => {
    let label;
    if (point !== undefined) {
      const info = POINTS_ALL.find((p) => p.code === point);
      if (info) {
        label = t(info.label);
      }
    } else {
      label = t("point.na");
    }
    return label;
  };

  const renderSkills = () => {
    return professionalSkill.map((skill, index) => {
      return (
        <div key={`skill_${index}`}>
          <div className="row">
            <div className="col-11 d-flex">
              <div className="me-2">
                <strong>{`${index + 1}.`}</strong>
              </div>
              <div className="me-2">
                <strong>{`(${skill?.weight}%)`}</strong>
              </div>
              <div>{`${skill?.name}`}</div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center mt-3">
            <ContainerTooltipSup
              className="col-11"
              data-tooltip-id={`tooltip_skill_${index}`}
            >
              {comments[`autoSkill${index + 1}`] && (
                <Tooltip id={`tooltip_skill_${index}`}>
                  <ContainerTooltip>
                    {renderComment(`autoSkill${index + 1}`)}
                  </ContainerTooltip>
                </Tooltip>
              )}
              <AutoLabel>
                <AutoIcon
                  className="fa-solid fa-user-pen"
                  title={t("forms.evaluation.labels.autoEval")}
                ></AutoIcon>
              </AutoLabel>
              <AutoValue>
                <span className="me-1">
                  {t(`forms.evaluation.labels.resultAutoEval`)}
                </span>
                <strong>{getPoints(skill?.autoPoints)}</strong>
                {comments[`autoSkill${index + 1}`] && (
                  <i className="fa-solid fa-circle-info"></i>
                )}
              </AutoValue>
            </ContainerTooltipSup>
          </div>
          <div className="row align-items-center justify-content-center mt-2 mb-3">
            <div className="col-8">
              <Select
                id={`point${index}`}
                name={`point${index}`}
                // value={year}
                onChange={(value) => {
                  onSelectSkillValue(value, index);
                }}
                options={points}
                classNamePrefix="dark"
                placeholder=""
                // className="form-control"
              />
            </div>
            <div className="col-3">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => onJustify(index + 1, "finalSkill")}
              >
                {t("forms.evaluation.buttons.comments")}
              </button>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderComment = (id) => {
    return (
      <>
        {comments[id] && (
          <>
            <div className="mt-2">
              <strong>Comentario del trabajador:</strong>
            </div>
            <div>{comments[id]}</div>
          </>
        )}
      </>
    );
  };

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">
            {t("forms.evaluation.evaluation", {
              user: `${evaluation.dataUser.name} ${evaluation.dataUser.surname}`,
            })}
          </h3>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row justify-content-center">
              <Subtitle className="col-10 col-sm-11 mt-4 mb-4">
                {t("forms.evaluation.labels.criterialObj")}
              </Subtitle>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 d-flex">
                <div className="me-2">
                  <strong>1.</strong>
                </div>
                <div className="me-2">
                  <strong>{`(${evaluation?.weightCriterial01}%)`}</strong>
                </div>
                <div>{`${evaluation?.criterial01}`}</div>
              </div>
            </div>
            <div className="row align-items-center justify-content-center mt-3">
              <ContainerTooltipSup className="col-10">
                {comments["auto1"] && (
                  <Tooltip id={`tooltip_data01`}>
                    <ContainerTooltip>
                      {renderComment("auto1")}
                    </ContainerTooltip>
                  </Tooltip>
                )}
                <AutoLabel>
                  <AutoIcon
                    className="fa-solid fa-user-pen"
                    title={t("forms.evaluation.labels.autoEval")}
                  ></AutoIcon>
                </AutoLabel>
                <AutoValue>
                  <span className="me-1">
                    {t(`forms.evaluation.labels.resultAutoEval`)}
                  </span>
                  <strong>{getPoints(evaluation?.pointAuto01)}</strong>
                  {comments[`auto1`] && (
                    <InfoIcon
                      data-tooltip-id={`tooltip_data01`}
                      className="fa-solid fa-circle-info"
                    ></InfoIcon>
                  )}
                </AutoValue>
              </ContainerTooltipSup>
            </div>
            <div className="row align-items-center justify-content-center mt-2 mb-4">
              <div className="col-7">
                <Field
                  id="point01"
                  name="point01"
                  // labelField={t("forms.evaluation.labels.pointAuto01")}
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={POINTS.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  addOptionEmpty
                />
              </div>
              <div className="col-3">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => onJustify(1, "final")}
                >
                  {t("forms.evaluation.buttons.comments")}
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 d-flex">
                <div className="me-2">
                  <strong>2.</strong>
                </div>
                <div className="me-2">
                  <strong>{`(${evaluation?.weightCriterial02}%)`}</strong>
                </div>
                <div>{`${evaluation?.criterial02}`}</div>
              </div>
            </div>
            <div className="row align-items-center justify-content-center mt-3">
              <ContainerTooltipSup className="col-10">
                {comments["auto2"] && (
                  <Tooltip id={`tooltip_data02`}>
                    <ContainerTooltip>
                      {renderComment("auto2")}
                    </ContainerTooltip>
                  </Tooltip>
                )}
                <AutoLabel>
                  <AutoIcon
                    className="fa-solid fa-user-pen"
                    title={t("forms.evaluation.labels.autoEval")}
                  ></AutoIcon>
                </AutoLabel>
                <AutoValue>
                  <span className="me-1">
                    {t(`forms.evaluation.labels.resultAutoEval`)}
                  </span>
                  <strong>{getPoints(evaluation?.pointAuto02)}</strong>
                  {comments[`auto2`] && (
                    <InfoIcon
                      data-tooltip-id={`tooltip_data02`}
                      className="fa-solid fa-circle-info"
                    ></InfoIcon>
                  )}
                </AutoValue>
              </ContainerTooltipSup>
            </div>
            <div className="row  align-items-center justify-content-center mt-2 mb-4">
              <div className="col-7">
                <Field
                  id="point02"
                  name="point02"
                  // labelField={t("forms.evaluation.labels.pointAuto01")}
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={POINTS.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  addOptionEmpty
                />
              </div>
              <div className="col-3">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => onJustify(2, "final")}
                >
                  {t("forms.evaluation.buttons.comments")}
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 d-flex">
                <div className="me-2">
                  <strong>3.</strong>
                </div>
                <div className="me-2">
                  <strong>{`(${evaluation?.weightCriterial03}%)`}</strong>
                </div>
                <div>{`${evaluation?.criterial03}`}</div>
              </div>
            </div>
            <div className="row align-items-center justify-content-center mt-3">
              <ContainerTooltipSup className="col-10">
                {comments["auto3"] && (
                  <Tooltip id={`tooltip_data03`}>
                    <ContainerTooltip>
                      {renderComment("auto3")}
                    </ContainerTooltip>
                  </Tooltip>
                )}
                <AutoLabel>
                  <AutoIcon
                    className="fa-solid fa-user-pen"
                    title={t("forms.evaluation.labels.autoEval")}
                  ></AutoIcon>
                </AutoLabel>
                <AutoValue>
                  <span className="me-1">
                    {t(`forms.evaluation.labels.resultAutoEval`)}
                  </span>
                  <strong>{getPoints(evaluation?.pointAuto03)}</strong>
                  {comments[`auto3`] && (
                    <InfoIcon
                      data-tooltip-id={`tooltip_data03`}
                      className="fa-solid fa-circle-info"
                    ></InfoIcon>
                  )}
                </AutoValue>
              </ContainerTooltipSup>
            </div>
            <div className="row  align-items-center justify-content-center mt-2 mb-4">
              <div className="col-7">
                <Field
                  id="point03"
                  name="point03"
                  // labelField={t("forms.evaluation.labels.pointAuto01")}
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={POINTS.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  addOptionEmpty
                />
              </div>
              <div className="col-3">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => onJustify(3, "final")}
                >
                  {t("forms.evaluation.buttons.comments")}
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 d-flex">
                <div className="me-2">
                  <strong>4.</strong>
                </div>
                <div className="me-2">
                  <strong>{`(${evaluation?.weightCriterial04}%)`}</strong>
                </div>
                <div>{`${evaluation?.criterial04}`}</div>
              </div>
            </div>
            <div className="row align-items-center justify-content-center mt-3">
              <ContainerTooltipSup className="col-10">
                {comments["auto4"] && (
                  <Tooltip id={`tooltip_data04`}>
                    <ContainerTooltip>
                      {renderComment("auto4")}
                    </ContainerTooltip>
                  </Tooltip>
                )}
                <AutoLabel>
                  <AutoIcon
                    className="fa-solid fa-user-pen"
                    title={t("forms.evaluation.labels.autoEval")}
                  ></AutoIcon>
                </AutoLabel>
                <AutoValue>
                  <span className="me-1">
                    {t(`forms.evaluation.labels.resultAutoEval`)}
                  </span>
                  <strong>{getPoints(evaluation?.pointAuto04)}</strong>
                  {comments[`auto4`] && (
                    <InfoIcon
                      data-tooltip-id={`tooltip_data04`}
                      className="fa-solid fa-circle-info"
                    ></InfoIcon>
                  )}
                </AutoValue>
              </ContainerTooltipSup>
            </div>
            <div className="row  align-items-center justify-content-center mt-2 mb-4">
              <div className="col-7">
                <Field
                  id="point04"
                  name="point04"
                  // labelField={t("forms.evaluation.labels.pointAuto01")}
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={POINTS.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  addOptionEmpty
                />
              </div>
              <div className="col-3">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => onJustify(4, "final")}
                >
                  {t("forms.evaluation.buttons.comments")}
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 d-flex">
                <div className="me-2">
                  <strong>5.</strong>
                </div>
                <div className="me-2">
                  <strong>{`(${evaluation?.weightCriterial05}%)`}</strong>
                </div>
                <div>{`${evaluation?.criterial05}`}</div>
              </div>
            </div>
            <div className="row align-items-center justify-content-center mt-3">
              <ContainerTooltipSup
                className="col-10"
                data-tooltip-id={`tooltip_data05`}
              >
                {comments["auto5"] && (
                  <Tooltip id={`tooltip_data05`}>
                    <ContainerTooltip>
                      {renderComment("auto5")}
                    </ContainerTooltip>
                  </Tooltip>
                )}
                <AutoLabel>
                  <AutoIcon
                    className="fa-solid fa-user-pen"
                    title={t("forms.evaluation.labels.autoEval")}
                  ></AutoIcon>
                </AutoLabel>
                <AutoValue>
                  <span className="me-1">
                    {t(`forms.evaluation.labels.resultAutoEval`)}
                  </span>
                  <strong>{getPoints(evaluation?.pointAuto05)}</strong>
                  {comments[`auto5`] && (
                    <InfoIcon
                      data-tooltip-id={`tooltip_data05`}
                      className="fa-solid fa-circle-info"
                    ></InfoIcon>
                  )}
                </AutoValue>
              </ContainerTooltipSup>
            </div>
            <div className="row align-items-center justify-content-center mt-2">
              <div className="col-7">
                <Field
                  id="point05"
                  name="point05"
                  // labelField={t("forms.evaluation.labels.pointAuto01")}
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={POINTS.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  addOptionEmpty
                />
              </div>
              <div className="col-3">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => onJustify(5, "final")}
                >
                  {t("forms.evaluation.buttons.comments")}
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <Subtitle className="col-10 col-sm-11 mt-5 mb-4">
                {t("forms.evaluation.labels.criterialStd")}
              </Subtitle>
            </div>
            <div className="row justify-content-center">
              <div className="col-10 col-sm-11">{renderSkills()}</div>
            </div>

            <div className="row mt-5 mb-5">
              <div className="col-12 text-center">
                <button
                  type="button"
                  onClick={() => cancelAction()}
                  className="btn btn-lg btn-secondary me-2"
                >
                  {t("forms.evaluation.buttons.cancel")}
                </button>
                <button type="submit" className="btn btn-lg btn-primary">
                  {t("forms.evaluation.buttons.save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

EvalFormView.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const EvalFormViewF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "EvalFormView",
})(EvalFormView);

export default EvalFormViewF;
