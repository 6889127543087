import React from "react";
import { useTranslation } from "react-i18next";
import { EVAL_STATUS, POINTS, POINTS_ALL } from "../../../../constants/index";
import {
  LabelText,
  ValueText,
  Subtitle,
  AutoLabel,
  AutoIcon,
  AutoValue,
  ContainerTooltip,
} from "./evaluationConsult.styled";
import { Tooltip } from "react-tooltip";

const DetailEvaluationView = (props) => {
  const { t } = useTranslation();
  const { data, cancelAction } = props;
  const evaluation = data && data.evaluation ? data.evaluation[0] : data;
  const status = EVAL_STATUS.find((f) => f.code === evaluation.status);

  const getPoints = (point) => {
    let label;
    if (point !== undefined) {
      const info = POINTS_ALL.find((p) => p.code === point);
      if (info) {
        label = t(info.label);
      }
    } else {
      label = t("point.na");
    }
    return label;
  };

  const renderSkills = () => {
    let listSkills = evaluation.professionalSkill;
    return listSkills.map((skill, index) => {
      return (
        <div key={`skill_${index}`}>
          <div className="row">
            <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
              {`${index + 1}.`}
            </div>
            <div className="col-1 mt-2">{`${skill.weight}%`}</div>
            <div className="col-10 mt-2">{skill.name}</div>
          </div>
          {skill?.autoPoints && (
            <div className="row align-items-center mt-2 mb-3">
              <div className="col-2"></div>
              <div
                className="col-5"
                data-tooltip-id={`tooltip_skill_point_${index}`}
              >
                <Tooltip id={`tooltip_skill_point_${index}`}>
                  <ContainerTooltip>
                    {t(`forms.evaluation.labels.autoEval`, {
                      obj: skill?.name,
                    })}
                  </ContainerTooltip>
                </Tooltip>
                <AutoLabel>
                  <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                </AutoLabel>
                <AutoValue>
                  <strong>{`${getPoints(skill?.autoPoints)}`}</strong>
                </AutoValue>
              </div>
              {skill.points && (
                <div
                  className="col-5"
                  data-tooltip-id={`tooltip_manager05_${index}`}
                >
                  <Tooltip id={`tooltip_manager05_${index}`}>
                    <ContainerTooltip>
                      {t(`forms.evaluation.labels.evalManager`, {
                        obj: skill?.name,
                      })}
                    </ContainerTooltip>
                  </Tooltip>
                  <AutoLabel>
                    <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                  </AutoLabel>
                  <AutoValue>
                    <strong>{`${getPoints(skill?.points)}`}</strong>
                  </AutoValue>
                </div>
              )}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <h3 className="title">{t("forms.evaluation.detail")}</h3>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <div className="row justify-content-center">
            <div className="col-12 mt-3 d-flex">
              <LabelText>{t("forms.evaluation.labels.evaluated")}:</LabelText>
              <ValueText>{`${data.name || data.dataUser.name} ${
                data.surname || data.dataUser.surname
              }`}</ValueText>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 d-flex">
              <LabelText>{t("forms.evaluation.labels.year")}:</LabelText>
              <ValueText>{`${evaluation.year}`}</ValueText>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 d-flex">
              <LabelText>{t("forms.evaluation.labels.status")}:</LabelText>
              <ValueText>{`${t(status.label)}`}</ValueText>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 d-flex">
              <LabelText>{t("forms.evaluation.labels.evaluator")}:</LabelText>
              <ValueText>{`${data.evaluator.name} ${data.evaluator.surname}`}</ValueText>
            </div>
          </div>
          <div className="row justify-content-center">
            <Subtitle className="col-12 mt-4 mb-2">
              {t("forms.evaluation.labels.criterialObj")}
            </Subtitle>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end"></div>
                <div className="col-1 mt-2 text-start">
                  {t("forms.evaluation.labels.weight")}
                </div>
                <div className="col-10 mt-2 text-start">
                  {t("forms.evaluation.labels.criterial")}
                </div>
              </div>
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  1.
                </div>
                <div className="col-1 mt-2">
                  {`${evaluation.weightCriterial01}%`}
                </div>
                <div className="col-10 mt-2 text-start">
                  {`${evaluation.criterial01}`}
                </div>
              </div>
              {evaluation?.pointAuto01 && (
                <div className="row align-items-center mt-3 mb-4">
                  <div className="col-2"></div>
                  <div
                    className="col-5 text-start"
                    data-tooltip-id={`tooltip_data01`}
                  >
                    <Tooltip id={`tooltip_data01`}>
                      <ContainerTooltip>
                        {t(`forms.evaluation.labels.autoEval`, {
                          obj: t(`forms.evaluation.labels.criterial01`),
                        })}
                      </ContainerTooltip>
                    </Tooltip>
                    <AutoLabel>
                      <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                    </AutoLabel>
                    <AutoValue>
                      {`${getPoints(evaluation?.pointAuto01)}`}
                    </AutoValue>
                  </div>
                  {evaluation.point01 && (
                    <div
                      className="col-5 text-start"
                      data-tooltip-id={`tooltip_manager01`}
                    >
                      <Tooltip id={`tooltip_manager01`}>
                        <ContainerTooltip>
                          {t(`forms.evaluation.labels.evalManager`, {
                            obj: t(`forms.evaluation.labels.criterial01`),
                          })}
                        </ContainerTooltip>
                      </Tooltip>
                      <AutoLabel>
                        <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                      </AutoLabel>
                      <AutoValue>
                        {`${getPoints(evaluation?.point01)}`}
                      </AutoValue>
                    </div>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  2.
                </div>
                <div className="col-1 mt-2">
                  {`${evaluation.weightCriterial02}%`}
                </div>
                <div className="col-10 mt-2 text-start">
                  {`${evaluation.criterial02}`}
                </div>
              </div>
              {evaluation?.pointAuto02 && (
                <div className="row align-items-center mt-3 mb-4">
                  <div className="col-2"></div>
                  <div
                    className="col-5 text-start"
                    data-tooltip-id={`tooltip_data02`}
                  >
                    <Tooltip id={`tooltip_data02`}>
                      <ContainerTooltip>
                        {t(`forms.evaluation.labels.autoEval`, {
                          obj: t(`forms.evaluation.labels.criterial02`),
                        })}
                      </ContainerTooltip>
                    </Tooltip>
                    <AutoLabel>
                      <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                    </AutoLabel>
                    <AutoValue>
                      {`${getPoints(evaluation?.pointAuto02)}`}
                    </AutoValue>
                  </div>
                  {evaluation.point02 && (
                    <div
                      className="col-5 text-start"
                      data-tooltip-id={`tooltip_manager02`}
                    >
                      <Tooltip id={`tooltip_manager02`}>
                        <ContainerTooltip>
                          {t(`forms.evaluation.labels.evalManager`, {
                            obj: t(`forms.evaluation.labels.criterial02`),
                          })}
                        </ContainerTooltip>
                      </Tooltip>
                      <AutoLabel>
                        <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                      </AutoLabel>
                      <AutoValue>
                        {`${getPoints(evaluation?.point02)}`}
                      </AutoValue>
                    </div>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  3.
                </div>
                <div className="col-1 mt-2">
                  {`${evaluation.weightCriterial03}%`}
                </div>
                <div className="col-10 mt-2 text-start">
                  {`${evaluation.criterial03}`}
                </div>
              </div>
              {evaluation?.pointAuto03 && (
                <div className="row align-items-center mt-3 mb-4">
                  <div className="col-2"></div>
                  <div
                    className="col-5 text-start"
                    data-tooltip-id={`tooltip_data03`}
                  >
                    <Tooltip id={`tooltip_data03`}>
                      <ContainerTooltip>
                        {t(`forms.evaluation.labels.autoEval`, {
                          obj: t(`forms.evaluation.labels.criterial03`),
                        })}
                      </ContainerTooltip>
                    </Tooltip>
                    <AutoLabel>
                      <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                    </AutoLabel>
                    <AutoValue>
                      {`${getPoints(evaluation?.pointAuto03)}`}
                    </AutoValue>
                  </div>
                  {evaluation.point03 && (
                    <div
                      className="col-5 text-start"
                      data-tooltip-id={`tooltip_manager03`}
                    >
                      <Tooltip id={`tooltip_manager03`}>
                        <ContainerTooltip>
                          {t(`forms.evaluation.labels.evalManager`, {
                            obj: t(`forms.evaluation.labels.criterial03`),
                          })}
                        </ContainerTooltip>
                      </Tooltip>
                      <AutoLabel>
                        <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                      </AutoLabel>
                      <AutoValue>
                        {`${getPoints(evaluation?.point03)}`}
                      </AutoValue>
                    </div>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  4.
                </div>
                <div className="col-1 mt-2">
                  {`${evaluation.weightCriterial04}%`}
                </div>
                <div className="col-10 mt-2 text-start">
                  {`${evaluation.criterial04}`}
                </div>
              </div>
              {evaluation?.pointAuto04 && (
                <div className="row align-items-center mt-3 mb-4">
                  <div className="col-2"></div>
                  <div
                    className="col-5 text-start"
                    data-tooltip-id={`tooltip_data04`}
                  >
                    <Tooltip id={`tooltip_data04`}>
                      <ContainerTooltip>
                        {t(`forms.evaluation.labels.autoEval`, {
                          obj: t(`forms.evaluation.labels.criterial04`),
                        })}
                      </ContainerTooltip>
                    </Tooltip>
                    <AutoLabel>
                      <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                    </AutoLabel>
                    <AutoValue>
                      {`${getPoints(evaluation?.pointAuto04)}`}
                    </AutoValue>
                  </div>
                  {evaluation.point04 && (
                    <div
                      className="col-5 text-start"
                      data-tooltip-id={`tooltip_manager04`}
                    >
                      <Tooltip id={`tooltip_manager04`}>
                        <ContainerTooltip>
                          {t(`forms.evaluation.labels.evalManager`, {
                            obj: t(`forms.evaluation.labels.criterial04`),
                          })}
                        </ContainerTooltip>
                      </Tooltip>
                      <AutoLabel>
                        <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                      </AutoLabel>
                      <AutoValue>
                        {`${getPoints(evaluation?.point04)}`}
                      </AutoValue>
                    </div>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  5.
                </div>
                <div className="col-1 mt-2">
                  {`${evaluation.weightCriterial05}%`}
                </div>
                <div className="col-10 mt-2 text-start">
                  {`${evaluation.criterial05}`}
                </div>
              </div>
              {evaluation?.pointAuto05 && (
                <div className="row align-items-center mt-3 mb-4">
                  <div className="col-2"></div>
                  <div
                    className="col-5 text-start"
                    data-tooltip-id={`tooltip_data05`}
                  >
                    <Tooltip id={`tooltip_data05`}>
                      <ContainerTooltip>
                        {t(`forms.evaluation.labels.autoEval`, {
                          obj: t(`forms.evaluation.labels.criterial05`),
                        })}
                      </ContainerTooltip>
                    </Tooltip>
                    <AutoLabel>
                      <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                    </AutoLabel>
                    <AutoValue>
                      {`${getPoints(evaluation?.pointAuto05)}`}
                    </AutoValue>
                  </div>
                  {evaluation.point05 && (
                    <div
                      className="col-5  text-start"
                      data-tooltip-id={`tooltip_manager05`}
                    >
                      <Tooltip id={`tooltip_manager05`}>
                        <ContainerTooltip>
                          {t(`forms.evaluation.labels.evalManager`, {
                            obj: t(`forms.evaluation.labels.criterial05`),
                          })}
                        </ContainerTooltip>
                      </Tooltip>
                      <AutoLabel>
                        <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                      </AutoLabel>
                      <AutoValue>
                        {`${getPoints(evaluation?.point05)}`}
                      </AutoValue>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="row justify-content-center">
            <Subtitle className="col-12 mt-3 mb-2">
              {t("forms.evaluation.labels.criterialStd")}
            </Subtitle>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end"></div>
                <div className="col-1 mt-2 text-start">
                  {t("forms.evaluation.labels.weight")}
                </div>
                <div className="col-10 mt-2 text-start">
                  {t("forms.evaluation.labels.skill")}
                </div>
              </div>
            </div>
          </div>
          {renderSkills()}
          <div className="row mt-5 mb-5">
            <div className="col-12 text-center">
              <button
                type="button"
                onClick={() => cancelAction()}
                className="btn btn-lg btn-secondary me-2"
              >
                {t("forms.evaluation.buttons.close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

DetailEvaluationView.propTypes = {};

export default DetailEvaluationView;
