import * as Yup from "yup";

export const dataModel = {
  year: new Date().getFullYear(),
  evaluated: "",
  evaluator: "",
  status: "",
};

export const validationDataModel = Yup.object().shape({
  year: Yup.number().optional("forms.validations.required"),
  evaluated: Yup.string().optional("forms.validations.required"),
  evaluator: Yup.string().optional("forms.validations.required"),
  status: Yup.string().optional("forms.validations.required"),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
