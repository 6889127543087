import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { withTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./evaluationFilter.data.form";
import InputSelect from "../../../utils/InputSelect";
import Autocomplete from "../../../utils/InputAutocomplete";
import { EVAL_STATUS, ORIGINS, DESTINIES } from "../../../../constants/index";
import InputDate from "../../../utils/InputDate";
import InputText from "../../../utils/InputText";

const ConsultFilterForm = (props) => {
  const { t, handleSubmit, filtered, onClean, onFilterUser } = props;

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let index = 0; index < 5; index++) {
    years.push({
      code: currentYear - index,
      label: currentYear - index,
    });
  }

  const renderListUser = (item) => {
    return item.completeName;
  };

  // const [year, setYear] = useState({ value: currentYear, label: currentYear });

  return (
    <div className="row no-margin">
      <div className="col-12 mb-3">
        <div className="accordion" id="orderFilter">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                {t("forms.evaluation.filter")}
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#orderFilter"
            >
              <div className="accordion-body">
                <form
                  className="form-horizontal"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-2">
                      <Field
                        id="year"
                        name="year"
                        labelField={t("forms.evaluation.labels.year")}
                        component={InputSelect}
                        placeholder=""
                        optionLabel="label"
                        optionValue="code"
                        options={years.map((item) => ({
                          ...item,
                          label: t(item.label),
                        }))}
                        classNamePrefix="dark"
                        addOptionEmpty
                        classNameContainer="flex-grow-1"
                      />
                    </div>
                    <div className="col-3">
                      <Field
                        id="status"
                        name="status"
                        labelField={t("forms.evaluation.labels.status")}
                        component={InputSelect}
                        placeholder=""
                        optionLabel="label"
                        optionValue="code"
                        options={EVAL_STATUS.map((item) => ({
                          ...item,
                          label: t(item.label),
                        }))}
                        classNamePrefix="dark"
                        addOptionEmpty
                        classNameContainer="flex-grow-1"
                      />
                    </div>
                    <div className="col-3">
                      {/* <Field
                        id="evaluated"
                        className="form-control"
                        labelField={t("forms.evaluation.labels.evaluated")}
                        name="evaluated"
                        component={InputText}
                        fieldType="text"
                        placeholder=""
                      /> */}

                      <Field
                        id="evaluated"
                        className="form-control"
                        labelField={t("forms.evaluation.labels.evaluated")}
                        name="evaluated"
                        mandatory
                        component={Autocomplete}
                        fieldType="text"
                        onSearch={onFilterUser}
                        optionValue="uuid"
                        optionLabel="completeName"
                        textButtonReset={t("clear")}
                        renderItems={renderListUser}
                      />
                    </div>
                    <div className="col-3">
                      <Field
                        id="evaluator"
                        className="form-control"
                        labelField={t("forms.evaluation.labels.evaluator")}
                        name="evaluator"
                        mandatory
                        component={Autocomplete}
                        fieldType="text"
                        onSearch={onFilterUser}
                        optionValue="uuid"
                        optionLabel="completeName"
                        textButtonReset={t("clear")}
                        renderItems={renderListUser}
                      />

                      {/* <Field
                        id="evaluator"
                        className="form-control"
                        labelField={t("forms.evaluation.labels.evaluator")}
                        name="evaluator"
                        component={InputText}
                        fieldType="text"
                        placeholder=""
                      /> */}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary me-2"
                      >
                        <i className="fas fa-search"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg btn-secondary"
                        onClick={() => onClean(props.resetForm)}
                        title={t("cleanFilter")}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConsultFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onClean: PropTypes.func,
  filtered: PropTypes.bool,
};

const ConsultFilterFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "ConsultFilterForm",
})(withTranslation()(ConsultFilterForm));

export default ConsultFilterFormF;
