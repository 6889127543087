import { createAction } from "redux-actions";
import {
  CLEAN_AUTH,
  REFRESH_TOKEN,
  SET_AUTH_TOKEN,
  SET_AUTH_USER,
  GET_PROFILE,
} from "../../constants/index";
import { apiGet } from "../../api/index";
import {
  urlRefreshToken,
  urlLogout,
  urlGetProfile,
  urlDataUser,
  urlDataUserFilterAll,
} from "../../api/urls";

const generateFilterAllQuery = ({ filters }) => {
  let filter = "?";

  if (filters) {
    if (filters.name !== undefined) filter += "&name=" + filters.name;
  }

  return filter;
};

export const logoutUser = createAction(CLEAN_AUTH, (data) => data);

export const setAuthUser = createAction(SET_AUTH_USER, (data) => data);
export const setAuthToken = createAction(SET_AUTH_TOKEN, (data) => data);

/**
 * User Refresh token.
 */
export const userRefreshToken = createAction(REFRESH_TOKEN, (obj) =>
  apiGet(urlRefreshToken, undefined, undefined)()
);

/**
 * User Refresh token.
 */
export const getProfile = createAction(GET_PROFILE, () =>
  apiGet(urlGetProfile, undefined, undefined)()
);

/**
 * User Refresh token.
 */
export const userLogout = createAction(CLEAN_AUTH, (obj) =>
  apiGet(urlLogout, undefined, undefined)()
);

/**
 * Get User Data.
 */
export const getDataUser = createAction("GET_USERDATA", (param) =>
  apiGet(urlDataUser, param, undefined)()
);

/**
 * Filter list evaluations.
 */
export const filterUser = createAction("FILTER_USER", (filter, param) =>
  apiGet(urlDataUserFilterAll + generateFilterAllQuery(filter), param, false)()
);
