import React from "react";
import { Modal } from "react-bootstrap";
import { connectModal } from "redux-modal";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ModalForm = ({ show, handleHide, body }) => {
  const { t } = useTranslation();
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleHide}
      centered
      backdrop="static"
      keyboard={false}
      backdropClassName="second"
      dialogClassName="second"
      // bsPrefix="second"
      className="second"
    >
      <Modal.Body>{body}</Modal.Body>
    </Modal>
  );
};

ModalForm.propTypes = {
  show: PropTypes.any,
  handleHide: PropTypes.func,
  body: PropTypes.any,
};

export default connectModal({ name: "ModalForm2" })(ModalForm);
