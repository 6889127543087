import * as Yup from "yup";

export const dataModel = {
  comments: "",
  date: "",
};

export const validationDataModel = () => {
  return Yup.object().shape({
    date: Yup.date().required("forms.validations.required"),
    comments: Yup.string()
      .optional("forms.validations.required")
      .max(100, { message: "forms.validations.max", args: { max: 1000 } }),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
