import { createSelector } from "reselect";

const getStateAuthSelector = (state) => state.auth;

export const getAuthUserSelector = createSelector(
  getStateAuthSelector,
  (auth) => {
    if (auth && auth.user) {
      return auth.user;
    }

    return undefined;
  }
);

export const getIsEvaluatedSelector = createSelector(
  getAuthUserSelector,
  (user) => {
    if (user && user.roles) {
      return user.roles.includes("evaluated");
    }

    return undefined;
  }
);

export const getDataTokenSelector = createSelector(
  getStateAuthSelector,
  (auth) => {
    if (auth && auth.dataToken) {
      return auth.dataToken;
    }

    return undefined;
  }
);
