import * as Yup from "yup";

export const dataModel = {
  pointAuto01: "",
  pointAuto02: "",
  pointAuto03: "",
  pointAuto04: "",
  pointAuto05: "",
};

export const validationDataModel = () => {
  return Yup.object().shape({
    pointAuto01: Yup.number().required("forms.validations.required"),
    pointAuto02: Yup.number().required("forms.validations.required"),
    pointAuto03: Yup.number().required("forms.validations.required"),
    pointAuto04: Yup.number().required("forms.validations.required"),
    pointAuto05: Yup.number().required("forms.validations.required"),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
