import { store } from "../store/index";
import {
  userRefreshToken,
  userLogout,
  setAuthUser,
  setAuthToken,
  getProfile,
  getDataUser,
  filterUser,
} from "../store/actions/AuthActions";

export const getUserData = async (uuid) => {
  const result = await store.dispatch(getDataUser([uuid]));
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const profile = async () => {
  const result = await store.dispatch(getProfile());
  if (result?.payload?.data) {
    return result.payload.data;
  }

  return null;
};

export const refreshToken = async () => {
  const result = await store.dispatch(userRefreshToken());
  if (result?.payload?.user) {
    await store.dispatch(setAuthUser(result.payload.user));
    await store.dispatch(
      setAuthToken({
        access_token: result.payload.access_token,
        token_expiration: result.payload.token_expiration,
      })
    );
    return result.payload;
  }

  return null;
};

export const logout = async () => {
  await store.dispatch(userLogout());

  return null;
};

/**
 * List User
 */
export const listFilterUser = async (filter) => {
  const result = await store.dispatch(filterUser(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
