import React from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./notifiedEvalForm.data.form";
import InputTextArea from "../../../utils/InputTextarea";
import moment from "moment-timezone";
import InputDate from "../../../utils/InputDate";

const NotifiedForm = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, cancelAction, evaluation } = props;
  const date = moment(new Date()).format("DD-MM-YYYY");

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{`${t("forms.evaluation.notified")}: ${
            evaluation
              ? `${evaluation.dataUser.name} ${evaluation.dataUser.surname}`
              : ""
          }`}</h3>
        </div>
      </div>

      <div className="row justify-content-center  ">
        <div className="col-12 col-md-11">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row justify-content-center">
              <div className="col-12 mt-3">
                <Field
                  id="date"
                  labelField={t("forms.evaluation.labels.dateNotified")}
                  className="form-control"
                  name="date"
                  component={InputDate}
                  language="es"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 mt-3">
                <Field
                  id="comment"
                  name="comment"
                  className="form-control"
                  labelField={t("forms.evaluation.labels.comment")}
                  component={InputTextArea}
                  // placeholder="Comment"
                  rows={5}
                />
              </div>
            </div>

            <div className="row mt-5 mb-5">
              <div className="col-12 text-center">
                <button
                  type="button"
                  onClick={() => cancelAction()}
                  className="btn btn-lg btn-secondary me-2"
                >
                  {t("forms.evaluation.buttons.cancel")}
                </button>
                <button type="submit" className="btn btn-lg btn-primary">
                  {t("forms.evaluation.buttons.save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

NotifiedForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const NotifiedFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "NotifiedForm",
})(NotifiedForm);

export default NotifiedFormF;
