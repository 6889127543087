import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./config.data.form";
import Breadcrumb from "../../utils/breadCrumbs";
import PATHS from "../../../constants/paths";
import { useNavigate } from "react-router-dom";
import InputDate from "../../utils/InputDate";
import { Container, Subtitle, FinishStep } from "./config.styled";
import moment from "moment-timezone";

const ConfigForm = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, onSendNotification, onCloseStep, data } = props;
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.config.title") },
  ];

  const readOnlyObj = data && data.dateFinishObj;
  const readOnlyEval = data && data.dateFinishEval;
  const readOnlyAutoEval = data && data.dateFinishAutoEval;

  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{`${t("forms.config.title")}`}</h3>
        </div>
      </div>

      <Breadcrumb items={breads} />
      <div className="row justify-content-center  ">
        <div className="col-12 col-md-11">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row">
              <Subtitle className="col-12">
                {`${t("forms.config.objStep")}`}
              </Subtitle>
            </div>
            <div className="row no-margin">
              <Container className="col-12">
                <div className="row">
                  <div className="col-4">
                    <Field
                      id="dateInitObj"
                      labelField={t("forms.config.labels.dateInitObj")}
                      className="form-control"
                      name="dateInitObj"
                      component={InputDate}
                      language="es"
                      dateFormat="dd/MM/yyyy"
                      readOnly={readOnlyObj}
                    />
                  </div>
                  <div className="col-4">
                    <Field
                      id="dateEndObj"
                      labelField={t("forms.config.labels.dateEndObj")}
                      className="form-control"
                      name="dateEndObj"
                      component={InputDate}
                      language="es"
                      dateFormat="dd/MM/yyyy"
                      defaultHours={23}
                      defaultMinutes={59}
                      readOnly={readOnlyObj}
                    />
                  </div>
                  <div className="col-4 d-flex align-items-end">
                    {readOnlyObj ? (
                      <FinishStep>{`${t("stepFinished")} ${moment(
                        new Date(data.dateFinishObj)
                      ).format("DD/MM/YYYY")}`}</FinishStep>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-md btn-secondary me-3"
                          onClick={() => onSendNotification("OBJ")}
                          disabled={readOnlyObj}
                        >
                          {t("forms.config.buttons.sendNotification")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-md btn-secondary"
                          onClick={() => onCloseStep("OBJ")}
                          disabled={readOnlyObj}
                        >
                          {t("forms.config.buttons.closeStep")}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </Container>
            </div>
            <div className="row mt-4">
              <Subtitle className="col-12">
                {`${t("forms.config.evaAutoStep")}`}
              </Subtitle>
            </div>
            <div className="row no-margin">
              <Container className="col-12">
                <div className="row">
                  <div className="col-4">
                    <Field
                      id="dateInitAutoEval"
                      labelField={t("forms.config.labels.dateInitAutoEval")}
                      className="form-control"
                      name="dateInitAutoEval"
                      component={InputDate}
                      language="es"
                      dateFormat="dd/MM/yyyy"
                      readOnly={readOnlyAutoEval}
                    />
                  </div>
                  <div className="col-4">
                    <Field
                      id="dateEndAutoEval"
                      labelField={t("forms.config.labels.dateEndAutoEval")}
                      className="form-control"
                      name="dateEndAutoEval"
                      component={InputDate}
                      language="es"
                      dateFormat="dd/MM/yyyy"
                      defaultHours={23}
                      defaultMinutes={59}
                      readOnly={readOnlyAutoEval}
                    />
                  </div>
                  <div className="col-4 d-flex align-items-end">
                    {readOnlyAutoEval ? (
                      <FinishStep>{`${t("stepFinished")} ${moment(
                        new Date(data.dateFinishAutoEval)
                      ).format("DD/MM/YYYY")}`}</FinishStep>
                    ) : (
                      <>
                        {/* <button
                          type="button"
                          className="btn btn-md btn-secondary me-3"
                          onClick={() => onSendNotification("AUTO_EVAL")}
                          disabled={readOnlyAutoEval}
                        >
                          {t("forms.config.buttons.sendNotification")}
                        </button> */}
                        <button
                          type="button"
                          className="btn btn-md btn-secondary"
                          onClick={() => onCloseStep("AUTO_EVAL")}
                          disabled={readOnlyAutoEval}
                        >
                          {t("forms.config.buttons.closeStep")}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </Container>
            </div>
            <div className="row mt-4">
              <Subtitle className="col-12">
                {`${t("forms.config.evaStep")}`}
              </Subtitle>
            </div>
            <div className="row no-margin">
              <Container className="col-12">
                <div className="row">
                  <div className="col-4">
                    <Field
                      id="dateInitEval"
                      labelField={t("forms.config.labels.dateInitEval")}
                      className="form-control"
                      name="dateInitEval"
                      component={InputDate}
                      language="es"
                      dateFormat="dd/MM/yyyy"
                      readOnly={readOnlyEval}
                    />
                  </div>
                  <div className="col-4">
                    <Field
                      id="dateEndEval"
                      labelField={t("forms.config.labels.dateEndEval")}
                      className="form-control"
                      name="dateEndEval"
                      component={InputDate}
                      language="es"
                      dateFormat="dd/MM/yyyy"
                      defaultHours={23}
                      defaultMinutes={59}
                      readOnly={readOnlyEval}
                    />
                  </div>
                  <div className="col-4 d-flex align-items-end">
                    {readOnlyEval ? (
                      <FinishStep>{`${t("stepFinished")} ${moment(
                        new Date(data.dateFinishEval)
                      ).format("DD/MM/YYYY")}`}</FinishStep>
                    ) : (
                      <>
                        {/* <button
                          type="button"
                          className="btn btn-md btn-secondary me-3"
                          onClick={() => onSendNotification("EVAL")}
                          disabled={readOnlyEval}
                        >
                          {t("forms.config.buttons.sendNotification")}
                        </button> */}
                        <button
                          type="button"
                          className="btn btn-md btn-secondary"
                          onClick={() => onCloseStep("EVAL")}
                          disabled={readOnlyEval}
                        >
                          {t("forms.config.buttons.closeStep")}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </Container>
            </div>

            <div className="row mt-5 mb-5">
              <div className="col-12 text-center">
                <button type="submit" className="btn btn-lg btn-primary">
                  {t("forms.config.buttons.save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

ConfigForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const ConfigFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "ConfigForm",
})(ConfigForm);

export default ConfigFormF;
