import * as Yup from "yup";

export const dataModel = {
  dateInitObj: "",
  dateEndObj: "",
  dateInitEval: "",
  dateEndEval: "",
  dateInitAutoEval: "",
  dateEndAutoEval: "",
};

export const validationDataModel = () => {
  return Yup.object().shape({
    dateInitObj: Yup.date().required("forms.validations.required"),
    dateEndObj: Yup.date()
      .required("forms.validations.required")
      .min(Yup.ref("dateInitObj"), "forms.validations.dateStep.dateInitObj"),
    dateInitAutoEval: Yup.date()
      .required("forms.validations.required")
      .min(Yup.ref("dateEndObj"), "forms.validations.dateStep.dateEndObj"),
    dateEndAutoEval: Yup.date()
      .required("forms.validations.required")
      .min(
        Yup.ref("dateInitAutoEval"),
        "forms.validations.dateStep.dateInitAutoEval"
      ),
    dateInitEval: Yup.date()
      .required("forms.validations.required")
      .min(
        Yup.ref("dateEndAutoEval"),
        "forms.validations.dateStep.dateEndAutoEval"
      ),
    dateEndEval: Yup.date()
      .required("forms.validations.required")
      .min(Yup.ref("dateInitEval"), "forms.validations.dateStep.dateInitEval"),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
