import React, { useState } from "react";
import List from "../evaluationListAll";
import { useTranslation } from "react-i18next";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
// import Select from "react-select";
import Filter from "../evaluationFilter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as EvaluationService from "../../../../services/EvaluationServices";
import * as UserServices from "../../../../services/UserServices";
import { EVAL_STATUS } from "../../../../constants/index";
import * as XLSX from "xlsx";
import moment from "moment-timezone";

const queryClient = new QueryClient();

const EvalFilterListAllContainer = (props) => {
  const { t } = useTranslation();
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.evaluation.titleList") },
  ];
  const [filters, setFilters] = useState(undefined);

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters(undefined);
  };

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};
    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] !== undefined && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  const exportToExcel = async () => {
    const filter = {
      filters,
      sort: "-date",
      exportXls: true,
    };
    EvaluationService.listToExport(filter).then((result) => {
      const skills =
        result.docs && result.docs.length > 0
          ? result.docs[0].professionalSkill
          : null;
      const data = result.docs.map((eva) => {
        const status = EVAL_STATUS.find((st) => st.code === eva.status);
        return {
          A: eva.year,
          B: `${eva.dataUser.name} ${eva.dataUser.surname}`,
          C: `${eva.dataUser.email}`,
          D: `${eva.evaluator.name} ${eva.evaluator.surname}`,
          E: t(status.label),
          F: eva.weightCriterial01 ? eva.weightCriterial01 : "-",
          G: eva.weightCriterial02 ? eva.weightCriterial02 : "-",
          H: eva.weightCriterial03 ? eva.weightCriterial03 : "-",
          I: eva.weightCriterial04 ? eva.weightCriterial04 : "-",
          J: eva.weightCriterial05 ? eva.weightCriterial05 : "-",
          K: eva.point01 && eva.point01 !== -1 ? eva.point01 : "-",
          L: eva.point02 && eva.point02 !== -1 ? eva.point02 : "-",
          M: eva.point03 && eva.point03 !== -1 ? eva.point03 : "-",
          N: eva.point04 && eva.point04 !== -1 ? eva.point04 : "-",
          O: eva.point05 && eva.point05 !== -1 ? eva.point05 : "-",
          P: eva.professionalSkill[0].points
            ? eva.professionalSkill[0].points
            : "-",
          Q: eva.professionalSkill[1].points
            ? eva.professionalSkill[1].points
            : "-",
          R: eva.professionalSkill[2].points
            ? eva.professionalSkill[2].points
            : "-",
          S: eva.professionalSkill[3].points
            ? eva.professionalSkill[3].points
            : "-",
          T: eva.professionalSkill[4].points
            ? eva.professionalSkill[4].points
            : "-",
          U: eva.pointsObj ? eva.pointsObj : 0,
          V: eva.pointsStandard ? eva.pointsStandard : 0,
          W: eva.totalPoints ? eva.totalPoints : 0,
        };
      });
      data.unshift({
        A: "Año",
        B: "Evaluado",
        C: "Email del Evaluado",
        D: "Manager",
        E: "Estado",
        F: "% Peso Obj. 01",
        G: "% Peso Obj. 02",
        H: "% Peso Obj. 03",
        I: "% Peso Obj. 04",
        J: "% Peso Obj. 05",
        K: "Resultado Obj. 01",
        L: "Resultado Obj. 02",
        M: "Resultado Obj. 03",
        N: "Resultado Obj. 04",
        O: "Resultado Obj. 05",
        P: `Resultado ${skills[0] ? skills[0].name : "Comp. 01"}`,
        Q: `Resultado ${skills[1] ? skills[1].name : "Comp. 02"}`,
        R: `Resultado ${skills[2] ? skills[2].name : "Comp. 03"}`,
        S: `Resultado ${skills[3] ? skills[3].name : "Comp. 04"}`,
        T: `Resultado ${skills[4] ? skills[4].name : "Comp. 05"}`,
        U: "Puntuación Obj. (80%)",
        V: "Puntuación Competencias (20%)",
        W: "Puntuación final",
      });
      const filename = `Evaluations_export_${moment(new Date()).format(
        "DDMMyyyyHHmm"
      )}`;
      const sheetName = "Listado de evaluaciones";
      var ws = XLSX.utils.json_to_sheet(data, {
        header: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
        ],
        skipHeader: true,
        UTC: true,
        dateNF: "dd-mm-yyyy hh:mm;@",
      });
      // dateNF:'mm/dd/yyyy;@',cellDates:true, raw: true}
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, filename + ".xlsx");
    });
  };

  const handlerFilterUser = async (value) => {
    const filter = {
      filters: {
        name: value,
      },
    };

    const result = await UserServices.listFilterUser(filter);
    return result;
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.evaluation.titleList")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <Filter
            onSubmit={handlerOnSubmitFilter}
            onClean={handlerCleanFilter}
            onFilterUser={handlerFilterUser}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List className="marg-top-1rem" filters={filters} />
          </QueryClientProvider>
        </div>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-11 text-center">
          <button
            type="button"
            className="btn btn-lg btn-secondary"
            onClick={() => exportToExcel()}
          >
            <i className="fa-solid fa-file-excel icon-button"></i>{" "}
            {t("exportFileExcel")}
          </button>
        </div>
      </div>
    </>
  );
};

EvalFilterListAllContainer.propTypes = {};

export default EvalFilterListAllContainer;
