import React from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./justifyForm.data.form";
import InputTextArea from "../../../utils/InputTextarea";

const JustifyFormView = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, onCancel } = props;

  return (
    <div className="row">
      <div className="col-12">
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="row justify-content-center mt-2">
            <div className="col-11">
              <Field
                id="comment"
                name="comment"
                className="form-control"
                labelField={t("forms.evaluation.labels.comment")}
                component={InputTextArea}
                // placeholder="Comment"
                rows={5}
              />
            </div>
          </div>

          <div className="row mt-5 mb-5">
            <div className="col-12 text-center">
              <button
                type="button"
                onClick={() => onCancel()}
                className="btn btn-lg btn-secondary me-2"
              >
                {t("forms.evaluation.buttons.cancel")}
              </button>
              <button type="submit" className="btn btn-lg btn-primary">
                {t("forms.evaluation.buttons.save")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

JustifyFormView.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const JustifyFormViewF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "JustifyFormView",
})(JustifyFormView);

export default JustifyFormViewF;
