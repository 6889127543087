import React, { useState } from "react";
import NotifiedView from "./notifiedEvalForm.view";
import * as EvaluationServices from "../../../../services/EvaluationServices";
import { useNavigate } from "react-router-dom";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const NotifiedFormContainer = ({ evaluation, onCancel, onSuccess }) => {
  const { t, i18n } = useTranslation();

  const notified = async (values) => {
    try {
      const result = await EvaluationServices.notifiedEvaluation(
        values,
        evaluation.uuid
      );
      if (result) {
        const okAction = () => {
          onSuccess();
        };
        showDialog(
          t("forms.evaluation.message.notifiedEval.title"),
          t("forms.evaluation.message.notifiedEval.text"),
          "success",
          false,
          "OK",
          false,
          okAction
        );
      }
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const okAction = () => {
        notified(values);
      };
      const cancelAction = () => {
        // saveAutoEvaluation(values);
      };
      showDialog(
        t("forms.evaluation.dialog.notified.title"),
        t("forms.evaluation.dialog.notified.text", {
          name: `${evaluation.dataUser.name} ${evaluation.dataUser.surname}`,
        }),
        "question",
        true,
        t("forms.evaluation.dialog.notified.buttonOk"),
        false,
        okAction,
        t("forms.evaluation.dialog.notified.buttonNo"),
        cancelAction
      );
    } catch (error) {
      // console.log("Error", error);
    }
  };

  return (
    <>
      <NotifiedView
        onSubmit={handleSubmit}
        cancelAction={onCancel}
        evaluation={evaluation}
      />
    </>
  );
};

NotifiedFormContainer.propTypes = {};

export default NotifiedFormContainer;
