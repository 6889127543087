import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import { ButtonClose } from "./style";
import EvalForm from "../evaluationEvalForm";
import NotifiedForm from "../notifiedEvalForm";

export const useEvaluations = (refresh) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const completeEval = async (data) => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <EvalForm
                  finalEval
                  onCancel={() => handlerClosePopup("ModalForm")}
                  onSuccess={() => {
                    handlerClosePopup("ModalForm");
                    if (refresh) {
                      refresh();
                    }
                  }}
                  evaluation={data}
                ></EvalForm>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  const completeAutoEval = async (data) => {
    dispatch(
      show("ModalForm", {
        body: (
          <div>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <EvalForm
                  onCancel={() => handlerClosePopup("ModalForm")}
                  onSuccess={() => {
                    handlerClosePopup("ModalForm");
                    if (refresh) {
                      refresh();
                    }
                  }}
                  evaluation={data}
                ></EvalForm>
              </div>
            </div>
          </div>
        ),
      })
    );
  };

  const notifiedEval = async (data) => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <NotifiedForm
                  finalEval
                  onCancel={() => handlerClosePopup("ModalForm")}
                  onSuccess={() => {
                    handlerClosePopup("ModalForm");
                    if (refresh) {
                      refresh();
                    }
                  }}
                  evaluation={data}
                ></NotifiedForm>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  return {
    completeAutoEval,
    completeEval,
    notifiedEval,
  };
};
