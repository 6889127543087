import React, { useState, useEffect } from "react";
import * as EvaluationService from "../../../../services/EvaluationServices";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AutoPaginateList from "../../../utils/AutoPaginateList";
import { ButtonAction, ButtonClose, Icon } from "./evaluationConsult.styled";
import { show, hide } from "redux-modal";
import Detail from "./evaluationDetailview";

const OrderListContainer = ({ filters }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState(null);

  const columns = [
    {
      header: t("forms.consult.table.name"),
      accessorKey: "name",
      cell: (info) => {
        return `${info.row.original.name} ${info.row.original.surname}`;
      },
    },
    {
      header: t("forms.consult.table.email"),
      accessorKey: "email",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.consult.table.evaluator"),
      accessorKey: "evaluator",
      cell: (info) => {
        return `${info.row.original.evaluator.name} ${info.row.original.evaluator.surname}`;
      },
    },
    {
      header: t("forms.consult.table.actions"),
      accessorKey: "uuid",
      cell: (info) => renderAction(info.row.original),
    },
  ];

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const showEvaluation = async (data) => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Detail
                  data={data}
                  cancelAction={() => handlerClosePopup("ModalForm")}
                ></Detail>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  const renderAction = (data) => {
    return (
      <>
        {data.evaluation && data.evaluation.length > 0 && (
          <div className="text-center">
            <ButtonAction
              onClick={() => showEvaluation(data)}
              title={t("forms.consult.detail")}
            >
              <Icon className="fa-solid fa-file-lines"></Icon>
              {t("forms.consult.detail")}
            </ButtonAction>
          </div>
        )}
      </>
    );
  };

  const loadData = (filters) => {
    const filtersF = filters
      ? { filters }
      : { filters: { year: new Date().getFullYear() } };
    EvaluationService.consultEvaluations(filtersF).then((result) => {
      setData(result);
    });
  };

  useEffect(() => {
    loadData(filters || null);
  }, [filters]);

  return (
    <>
      <AutoPaginateList data={data} columns={columns}></AutoPaginateList>
    </>
  );
};

export default OrderListContainer;
