import styled from "styled-components";

export const TotalWeight = styled.div`
  color: ${({ $error }) => ($error ? "red" : "#000")};
`;

export const Subtitle = styled.div`
  font-size: 1.5rem;
  text-decoration: underline;
`;

export const ContainerTooltip = styled.div`
  max-width: 300px;
`;

export const AutoLabel = styled.span`
  margin-right: 10px;
`;

export const AutoIcon = styled.i`
  font-size: 1rem;
  color: #f9ae17;
`;

export const AutoValue = styled.span`
  font-size: 0.875rem;
`;

export const ContainerTooltipSup = styled.div`
  cursor: pointer;
`;
