import React, { useState, useEffect } from "react";
import EvalFormView from "./evaluationEvalForm.view";
import FinalEvalFormView from "./evaluationFinalEvalForm.view";
import * as EvaluationServices from "../../../../services/EvaluationServices";
import { useNavigate } from "react-router-dom";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import { ButtonClose } from "./evaluationEvalForm.styled";
import JustifyForm from "./justifyForm.view";

const EvalFormContainer = ({ evaluation, onCancel, onSuccess, finalEval }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [comments, setComments] = useState([]);
  const [initValues, setInitValues] = useState({});
  const [professionalSkill, setProfessionalSkill] = useState(
    evaluation.professionalSkill
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (evaluation && evaluation.comments) {
      setComments(evaluation.comments);
    }
  }, [evaluation]);

  const saveAutoEvaluation = async (values) => {
    try {
      if (comments && comments.length > 0) {
        values.comments = comments;
      }

      const result = await EvaluationServices.setAutoEvaluation(
        values,
        professionalSkill,
        evaluation.uuid
      );
      if (result) {
        const okAction = () => {
          onSuccess();
        };
        showDialog(
          t("forms.evaluation.message.autoEval.title"),
          t("forms.evaluation.message.autoEval.text"),
          "success",
          false,
          "OK",
          false,
          okAction
        );
      }
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const validateNA = (values, field, fieldCommentType) => {
    let response = 0;
    for (let i = 1; i < 6; i++) {
      if (values[`${field}${i}`] === -1) {
        const comm = comments.findIndex(
          (comment) => comment.obj === i && comment.type === fieldCommentType
        );
        if (comm === -1) {
          response = i;
          break;
        }
      }
    }
    return response;
  };

  const handleSubmit = async (values) => {
    for (const prfSkill of professionalSkill) {
      if (!prfSkill.autoPoints) {
        showDialog(
          t("forms.evaluation.message.autoEval.title"),
          t("forms.evaluation.message.autoEval.errorSkill"),
          "error",
          false,
          "OK"
        );
        return false;
      }
    }
    const naIsvalid = validateNA(values, "pointAuto0", "auto");
    if (naIsvalid !== 0) {
      showDialog(
        t("forms.evaluation.message.na.title"),
        t("forms.evaluation.message.na.text", { obj: naIsvalid }),
        "error",
        false,
        "OK"
      );
      return false;
    }

    try {
      const okAction = () => {
        saveAutoEvaluation(values);
      };
      const cancelAction = () => {
        // saveAutoEvaluation(values);
      };
      showDialog(
        t("forms.evaluation.dialog.saveAutoEval.title"),
        t("forms.evaluation.dialog.saveAutoEval.text"),
        "question",
        true,
        t("forms.evaluation.dialog.saveAutoEval.buttonOk"),
        false,
        okAction,
        t("forms.evaluation.dialog.saveAutoEval.buttonNo"),
        cancelAction
      );
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const saveEvaluation = async (values) => {
    try {
      if (comments && comments.length > 0) {
        values.comments = comments;
      }

      const result = await EvaluationServices.setEvaluation(
        values,
        professionalSkill,
        evaluation.uuid
      );
      if (result) {
        const okAction = () => {
          onSuccess();
        };
        showDialog(
          t("forms.evaluation.message.eval.title"),
          t("forms.evaluation.message.eval.text"),
          "success",
          false,
          "OK",
          false,
          okAction
        );
      }
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const handleSubmitFinal = async (values) => {
    for (const prfSkill of professionalSkill) {
      if (!prfSkill.points) {
        showDialog(
          t("forms.evaluation.message.eval.title"),
          t("forms.evaluation.message.eval.errorSkill"),
          "error",
          false,
          "OK"
        );
        return false;
      }
    }

    const naIsvalid = validateNA(values, "point0", "final");
    if (naIsvalid !== 0) {
      showDialog(
        t("forms.evaluation.message.na.title"),
        t("forms.evaluation.message.na.text", { obj: naIsvalid }),
        "error",
        false,
        "OK"
      );
      return false;
    }

    const okAction = () => {
      saveEvaluation(values);
    };
    const cancelAction = () => {
      // saveAutoEvaluation(values);
    };
    showDialog(
      t("forms.evaluation.dialog.confirm.title"),
      t("forms.evaluation.dialog.confirm.text"),
      "question",
      true,
      t("forms.evaluation.dialog.confirm.buttonOk"),
      false,
      okAction,
      t("forms.evaluation.dialog.confirm.buttonNo"),
      cancelAction
    );
  };

  const handlerOnSelectSkillValue = (value, index) => {
    const newSkill = [...professionalSkill];
    newSkill[index].autoPoints = value.value;
    setProfessionalSkill(newSkill);
  };

  const handlerOnSelectFinalSkillValue = (value, index) => {
    const newSkill = [...professionalSkill];
    newSkill[index].points = value.value;
    setProfessionalSkill(newSkill);
  };

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const handlerAddComment = (values, numObj, type) => {
    const newComment = [...comments];
    const indexData = newComment.findIndex(
      (comment) => comment.obj === numObj && comment.type === type
    );
    if (indexData !== -1) {
      newComment[indexData].comment = values.comment;
    } else {
      newComment.push({ obj: numObj, type: type, comment: values.comment });
    }
    setComments(newComment);
    handlerClosePopup("ModalForm2");
  };

  const handleOnJustify = (numObj, type) => {
    const initialValues = {};
    const indexData = comments.findIndex(
      (comment) => comment.obj === numObj && comment.type === type
    );
    if (indexData !== -1) {
      initialValues.comment = comments[indexData].comment;
    }
    dispatch(
      show("ModalForm2", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm2")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <JustifyForm
                  onCancel={() => handlerClosePopup("ModalForm2")}
                  onSubmit={(values) => {
                    handlerAddComment(values, numObj, type);
                  }}
                  initialValues={initialValues}
                ></JustifyForm>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  return (
    <>
      {finalEval ? (
        <FinalEvalFormView
          initialValues={initValues}
          onSelectSkillValue={handlerOnSelectFinalSkillValue}
          onSubmit={handleSubmitFinal}
          cancelAction={onCancel}
          professionalSkill={professionalSkill}
          evaluation={evaluation}
          onJustify={handleOnJustify}
        />
      ) : (
        <EvalFormView
          initialValues={initValues}
          onSelectSkillValue={handlerOnSelectSkillValue}
          onSubmit={handleSubmit}
          cancelAction={onCancel}
          professionalSkill={professionalSkill}
          evaluation={evaluation}
          onJustify={handleOnJustify}
        />
      )}
    </>
  );
};

EvalFormContainer.propTypes = {};

export default EvalFormContainer;
