import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./evaluationEvalForm.data.form";
import InputText from "../../../utils/InputText";
import InputTextArea from "../../../utils/InputTextarea";
import InputSelect from "../../../utils/InputSelect";
import Breadcrumb from "../../../utils/breadCrumbs";
import PATHS from "../../../../constants/paths";
import { ORIGINS, DESTINIES } from "../../../../constants/index";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { Subtitle, ContainerTooltip } from "./evaluationEvalForm.styled";
import { Tooltip } from "react-tooltip";
import { POINTS } from "../../../../constants/index";

const EvalFormView = (props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    cancelAction,
    onSelectSkillValue,
    professionalSkill,
    evaluation,
    onJustify,
  } = props;
  const navigate = useNavigate();
  const [current, setCurrent] = useState({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
  const [total, setTotal] = useState(0);

  const points = [];
  for (const point of POINTS) {
    if (point.code !== -1) {
      points.push({
        value: point.code,
        label: t(point.label),
      });
    }
  }

  // useEffect(() => {
  //   if (edit && evaluation) {
  //     const newCurrent = { ...current };
  //     const totalData =
  //       Number(evaluation.weightCriterial01) +
  //       Number(evaluation.weightCriterial02) +
  //       Number(evaluation.weightCriterial03) +
  //       Number(evaluation.weightCriterial04) +
  //       Number(evaluation.weightCriterial05);
  //     newCurrent[1] = Number(evaluation.weightCriterial01);
  //     newCurrent[2] = Number(evaluation.weightCriterial02);
  //     newCurrent[3] = Number(evaluation.weightCriterial03);
  //     newCurrent[4] = Number(evaluation.weightCriterial04);
  //     newCurrent[5] = Number(evaluation.weightCriterial05);
  //     setCurrent(newCurrent);
  //     setTotal(totalData);
  //   }
  // }, [edit]);

  // const onChangeWeight = (value, criteria) => {
  //   const newCurrent = { ...current };
  //   let result = total - newCurrent[criteria] + Number(value);
  //   newCurrent[criteria] = Number(value);
  //   setCurrent(newCurrent);
  //   setTotal(result);
  // };

  // const debounced = useDebouncedCallback((value, criteria) => {
  //   onChangeWeight(value, criteria);
  // }, 500);

  const renderSkills = () => {
    return professionalSkill.map((skill, index) => {
      return (
        <div key={`skill_${index}`}>
          <div className="row">
            <div className="col-11 d-flex">
              <div className="me-2">
                <strong>{`${index + 1}.`}</strong>
              </div>
              <div className="me-2">
                <strong>{`(${skill?.weight}%)`}</strong>
              </div>
              <div>{`${skill?.name}`}</div>
            </div>
          </div>
          <div className="row mt-2 mb-3">
            <div className="col-8">
              <Select
                id={`point${index}`}
                name={`point${index}`}
                // value={year}
                onChange={(value) => {
                  onSelectSkillValue(value, index);
                }}
                options={points}
                classNamePrefix="dark"
                placeholder=""
                // className="form-control"
              />
            </div>
            <div className="col-4">
              <button
                type="button"
                className="btn btn-primary btn-md"
                onClick={() => onJustify(index + 1, "autoSkill")}
              >
                {t("forms.evaluation.buttons.comments")}
              </button>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.evaluation.autoEval")}</h3>
          {/* <h3 className="title">{`${t("forms.evaluation.detail")}: ${
            evaluation
              ? `${evaluation.dataUser.name} ${evaluation.dataUser.surname}`
              : ""
          }`}</h3> */}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row justify-content-center">
              <Subtitle className="col-10 col-sm-11 mt-4 mb-4">
                {t("forms.evaluation.labels.criterialObj")}
              </Subtitle>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 d-flex">
                <div className="me-2">
                  <strong>1.</strong>
                </div>
                <div className="me-2">
                  <strong>{`(${evaluation?.weightCriterial01}%)`}</strong>
                </div>
                <div>{`${evaluation?.criterial01}`}</div>
              </div>
            </div>
            <div className="row justify-content-center mt-2 mb-4">
              <div className="col-8">
                <Field
                  id="pointAuto01"
                  name="pointAuto01"
                  // labelField={t("forms.evaluation.labels.pointAuto01")}
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={POINTS.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  addOptionEmpty
                />
              </div>
              <div className="col-3">
                <button
                  type="button"
                  className="btn btn-primary btn-md"
                  onClick={() => onJustify(1, "auto")}
                >
                  {t("forms.evaluation.buttons.comments")}
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 d-flex">
                <div className="me-2">
                  <strong>2.</strong>
                </div>
                <div className="me-2">
                  <strong>{`(${evaluation?.weightCriterial02}%)`}</strong>
                </div>
                <div>{`${evaluation?.criterial02}`}</div>
              </div>
            </div>
            <div className="row justify-content-center mt-2 mb-4">
              <div className="col-8">
                <Field
                  id="pointAuto02"
                  name="pointAuto02"
                  // labelField={t("forms.evaluation.labels.pointAuto01")}
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={POINTS.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  addOptionEmpty
                />
              </div>
              <div className="col-3">
                <button
                  type="button"
                  className="btn btn-primary btn-md"
                  onClick={() => onJustify(2, "auto")}
                >
                  {t("forms.evaluation.buttons.comments")}
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 d-flex">
                <div className="me-2">
                  <strong>3.</strong>
                </div>
                <div className="me-2">
                  <strong>{`(${evaluation?.weightCriterial03}%)`}</strong>
                </div>
                <div>{`${evaluation?.criterial03}`}</div>
              </div>
            </div>
            <div className="row justify-content-center mt-2 mb-4">
              <div className="col-8">
                <Field
                  id="pointAuto03"
                  name="pointAuto03"
                  // labelField={t("forms.evaluation.labels.pointAuto01")}
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={POINTS.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  addOptionEmpty
                />
              </div>
              <div className="col-3">
                <button
                  type="button"
                  className="btn btn-primary btn-md"
                  onClick={() => onJustify(3, "auto")}
                >
                  {t("forms.evaluation.buttons.comments")}
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 d-flex">
                <div className="me-2">
                  <strong>4.</strong>
                </div>
                <div className="me-2">
                  <strong>{`(${evaluation?.weightCriterial04}%)`}</strong>
                </div>
                <div>{`${evaluation?.criterial04}`}</div>
              </div>
            </div>
            <div className="row justify-content-center mt-2 mb-4">
              <div className="col-8">
                <Field
                  id="pointAuto04"
                  name="pointAuto04"
                  // labelField={t("forms.evaluation.labels.pointAuto01")}
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={POINTS.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  addOptionEmpty
                />
              </div>
              <div className="col-3">
                <button
                  type="button"
                  className="btn btn-primary btn-md"
                  onClick={() => onJustify(4, "auto")}
                >
                  {t("forms.evaluation.buttons.comments")}
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 d-flex">
                <div className="me-2">
                  <strong>5.</strong>
                </div>
                <div className="me-2">
                  <strong>{`(${evaluation?.weightCriterial05}%)`}</strong>
                </div>
                <div>{`${evaluation?.criterial05}`}</div>
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-8">
                <Field
                  id="pointAuto05"
                  name="pointAuto05"
                  // labelField={t("forms.evaluation.labels.pointAuto01")}
                  component={InputSelect}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={POINTS.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                  addOptionEmpty
                />
              </div>
              <div className="col-3">
                <button
                  type="button"
                  className="btn btn-primary btn-md"
                  onClick={() => onJustify(5, "auto")}
                >
                  {t("forms.evaluation.buttons.comments")}
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <Subtitle className="col-10 col-sm-11 mt-5 mb-4">
                {t("forms.evaluation.labels.criterialStd")}
              </Subtitle>
            </div>
            <div className="row justify-content-center">
              <div className="col-10 col-sm-11">{renderSkills()}</div>
            </div>

            <div className="row mt-5 mb-5">
              <div className="col-12 text-center">
                <button
                  type="button"
                  onClick={() => cancelAction()}
                  className="btn btn-lg btn-secondary me-2"
                >
                  {t("forms.evaluation.buttons.cancel")}
                </button>
                <button type="submit" className="btn btn-lg btn-primary">
                  {t("forms.evaluation.buttons.save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

EvalFormView.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const EvalFormViewF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "EvalFormView",
})(EvalFormView);

export default EvalFormViewF;
