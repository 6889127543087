import styled from "styled-components";

export const LabelText = styled.div`
  font-size: 1rem;
  margin-right: 10px;
`;

export const ValueText = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;

export const Subtitle = styled.div`
  font-size: 1.5rem;
  text-decoration: underline;
`;

export const ButtonAction = styled.button`
  margin-right: 2px;
  color: #f9ae17;
  font-size: 0.8rem;
  border: 0px;
  background: transparent;
  width: max-content;

  &:hover {
    color: #ff9800;
    font-size: 0.8rem;
  }
`;

export const Icon = styled.i`
  margin-right: 8px;
  font-size: 1rem;
`;

export const ButtonClose = styled.button`
  font-size: 1rem;
  border: 0px;
  background: transparent;
`;

export const AutoLabel = styled.span`
  margin-right: 10px;
`;

export const AutoIcon = styled.i`
  font-size: 1rem;
  color: #f9ae17;
`;

export const AutoValue = styled.span`
  font-size: 0.875rem;
  font-weight: bold;
`;

export const ContainerTooltip = styled.div`
  max-width: 300px;
`;
